<template>
    <div>
        <div
            v-if="id"
            class="d-flex flex-column flex-md-row justify-content-between align-items-center"
        >
            <h1 class="h2">
                Detalles de tienda
                <strong class="text-primary"> #{{ id }} </strong>
            </h1>
            <vs-switch
                v-if="$ability.hasScope('store:UpdateOwn')"
                v-model="enabledUpdate"
                :class="enabledUpdate ? 'bg-primary' : 'bg-secondary'"
            >
                🖊
            </vs-switch>
        </div>
        <div v-else>
            <h1 class="h2">Registrar nueva tienda</h1>
        </div>

        <div class="mt-3">
            <span v-if="isOnboarding">
                Comienza a crear tu primera tienda. Ingresa el nombre que desees
                , los datos de contacto y los datos de ubicación.
            </span>
            <fragment v-else>
                <span v-if="id">
                    Manten actualizada la información de tu tienda.
                </span>
                <span v-else>
                    En esta sección puedes registrar tantas tiendas como
                    necesites.
                </span>
            </fragment>
        </div>

        <div class="d-flex flex-wrap">
            <div
                v-if="id"
                class="col-12 mt-3 p-0 d-flex justify-content-around"
            >
                <small class="text-left">
                    📆💾 {{ itemComponent.createdAt | date }}
                </small>
                <small class="text-left">
                    📆✏ {{ itemComponent.updatedAt | date }}
                </small>
            </div>
            <div class="col-12 col-md-6 mt-5">
                <vs-input
                    v-model="itemComponent.name"
                    class="opacity-false"
                    border
                    :state="stateEnabled"
                    shadow
                    icon
                    label="Nombre de tienda"
                    :disabled="!enabledUpdate"
                >
                    <template
                        v-if="!isValidName & introValidators.name"
                        #message-danger
                    >
                        Nombre de tienda invalido
                    </template>
                </vs-input>
            </div>

            <div class="col-12 col-md-6 mt-5">
                <vs-input
                    v-model="itemComponent.phone"
                    class="opacity-false w-100"
                    border
                    :state="stateEnabled"
                    shadow
                    label="Celular de contacto"
                    type="number"
                    :disabled="!enabledUpdate"
                    @blur="introValidators.phone = true"
                >
                    <template
                        v-if="!isValidPhone & introValidators.phone"
                        #message-danger
                    >
                        Celular invalido
                    </template>
                </vs-input>
            </div>

            <div class="col-12 col-md-6 mt-5">
                <city-code-selector
                    v-model="itemComponent.cityCode"
                    :enabled="enabledUpdate"
                />
            </div>

            <div class="col-12 col-md-6 mt-5">
                <vs-input
                    v-model="itemComponent.address"
                    border
                    :state="stateEnabled"
                    shadow
                    class="opacity-false w-100"
                    label="Dirección. Ej. Cll 42 # 12 - 8"
                    placeholder="Cll 42 # 12 - 8"
                    :disabled="!enabledUpdate"
                    @blur="onBlurAddress"
                >
                    <template
                        v-if="!isValidAddress & introValidators.address"
                        #message-danger
                    >
                        Dirección invalida
                    </template>
                </vs-input>
            </div>

            <div class="col-12 col-md-6 mt-5">
                <vs-input
                    v-model="itemComponent.addressComplement"
                    border
                    :state="stateEnabled"
                    shadow
                    class="opacity-false w-100"
                    label="Complemento de Dirección. Ej Local 12"
                    placeholder="Local 12"
                    :disabled="!enabledUpdate"
                />
            </div>

            <div class="col-12 col-md-6 mt-5">
                <vs-input
                    v-model="itemComponent.neighborhood"
                    border
                    :state="stateEnabled"
                    shadow
                    class="opacity-false w-100"
                    label="Barrio / Localidad"
                    :disabled="!enabledUpdate"
                />
            </div>

            <fragment v-if="id">
                <div class="col-12 col-md-6 mt-5">
                    <vs-input
                        v-model="itemComponent.tin"
                        border
                        shadow
                        :state="stateEnabled"
                        class="opacity-false w-100"
                        label="Nit (Optional)"
                        :disabled="!enabledUpdate"
                    />
                </div>

                <div class="col-12 col-md-6 mt-5">
                    <coordinates-selector v-model="itemComponent.coordinates" />
                </div>
            </fragment>

            <div
                v-if="enabledUpdate"
                class="col-12 mt-4 d-flex justify-content-center"
            >
                <vs-button v-if="!id" size="large" @click="createItemComponent">
                    Crear Tienda
                </vs-button>
                <vs-button v-else size="large" @click="updateItemComponent">
                    Actualizar tienda
                </vs-button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import { addressRegex, phoneRegex, titleRegex } from "@/common/lib/regex";

import CityCodeSelector from "@/components/utils/CityCodeSelector.vue";
import CoordinatesSelector from "@/components/utils/CoordinatesSelector.vue";

export default {
    name: "StoreReduced",
    components: {
        CoordinatesSelector,
        CityCodeSelector
    },
    data: () => ({
        id: "",
        isLoading: false,
        enabledUpdate: false,
        loaderInstance: null,
        loadedAllData: false,
        openActiveModal: false,
        isOnboarding: false,
        itemComponent: {
            id: "",
            tin: "",
            name: "",
            phone: "",
            address: "",
            cityCode: "11001",
            neighborhood: "",
            addressComplement: "",
            coordinates: { latitude: 4.63082, longitude: -74.15035 }
        },
        introValidators: {
            phone: false,
            address: false,
            name: false
        }
    }),
    computed: {
        ...mapGetters("control", ["backgroundColor", "stateInputDark"]),
        isValidAddress() {
            return addressRegex.test(this.itemComponent.address);
        },
        isValidPhone() {
            return phoneRegex.test(this.itemComponent.phone);
        },
        isValidName() {
            return titleRegex.test(this.itemComponent.name);
        },
        stateEnabled() {
            return this.enabledUpdate ? this.stateInputDark : "";
        }
    },
    watch: {},
    created() {
        this.$watch(
            () => this.$route,
            (newVal, oldVal) => {
                if (newVal !== oldVal) {
                    location.reload();
                }
            },
            {
                deep: true
            }
        );
        this.isOnboarding = this.$route.path.includes("onboarding");
    },
    async mounted() {
        const verifyPageScope = this.$ability.verifyPageScope.bind(this);
        if ("id" in this.$route.params) {
            if (!(await verifyPageScope("store:GetOwn", "/app"))) return;
            const id = this.$route.params.id;
            this.id = id;
            await this.getBaseItem(id);
        } else {
            if (!(await verifyPageScope("store:CreateOwn", "/app"))) return;
            this.enabledUpdate = true;
            this.loadedAllData = true;
        }
    },
    methods: {
        ...mapActions("stores", [
            "getStoreOwnById",
            "updateStoreOwn",
            "createStoreOwn"
        ]),
        async getBaseItem(id) {
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "corners" });
            try {
                const data = await this.getStoreOwnById({ id });
                this.itemComponent = data;
                this.loadedAllData = true;
            } catch (error) {
                this.loaderInstance.close();
                await this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
                if (error.statusCode === 404) {
                    this.$router.push("/app/stores");
                }
            } finally {
                this.isLoading = false;
                this.loaderInstance.close();
            }
        },
        async updateItemComponent() {
            if (!this.validateData()) {
                return;
            }
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "corners" });
            try {
                await this.updateStoreOwn(this.getDataSend());
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Actualización exitosa",
                    icon: "success",
                    showCloseButton: true
                });
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
            } finally {
                this.loaderInstance.close();
                this.isLoading = false;
            }
        },
        async createItemComponent() {
            if (!this.validateData()) {
                return;
            }
            this.isLoading = true;
            this.loaderInstance = this.$vs.loading({ type: "corners" });
            try {
                const data = await this.createStoreOwn(this.getDataSend());
                const sound = this.$sounds.get("success");
                sound.volume(0.9);
                sound.play();
                this.loaderInstance.close();
                if (this.$route.path.includes("onboarding")) {
                    await this.$swal.fire({
                        background: this.backgroundColor,
                        title: "Creación exitosa",
                        icon: "success",
                        timer: 3000
                    });
                    return this.$router.push("/onboarding/inventories");
                }
                const dialogResult = await this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Creación exitosa",
                    text: `Id: ${data.merchantId}`,
                    icon: "success",
                    showDenyButton: true,
                    showCloseButton: true,
                    showCancelButton: true,
                    confirmButtonColor: "#02672D",
                    cancelButtonColor: "#253C99",
                    denyButtonColor: "#f07325",
                    confirmButtonText: "Ver detalle",
                    denyButtonText: "Ir a inicio",
                    cancelButtonText: "Nueva tienda"
                });
                if (dialogResult.isConfirmed) {
                    this.id = data.merchantId;
                    this.itemComponent = {
                        ...this.itemComponent,
                        id: data.merchantId,
                        ...data
                    };
                    this.$router.push(`/app/stores/reduced/${this.id}`);
                }
                if (dialogResult.isDenied) {
                    this.$router.push("/app/");
                }
                if (dialogResult.isDismissed) {
                    this.introValidators = {
                        phone: false,
                        address: false,
                        name: false
                    };
                    this.itemComponent = {
                        id: "",
                        tin: "",
                        name: "",
                        phone: "",
                        address: "",
                        cityCode: "11001",
                        neighborhood: "",
                        addressComplement: ""
                    };
                }
            } catch (error) {
                this.loaderInstance.close();
                const sound = this.$sounds.get("error");
                sound.volume(0.9);
                sound.play();
                await this.$swal.fire({
                    background: this.backgroundColor,
                    title: error.title,
                    text: error.message,
                    icon: error.icon
                });
                if (error.statusCode === 404) {
                    this.$router.push("/app/stores");
                }
            } finally {
                this.isLoading = false;
            }
        },
        validateData() {
            this.introValidators = {
                phone: true,
                address: true,
                name: true
            };

            const toComplete = [];

            if (!this.isValidName) {
                toComplete.push("Nombre");
            }

            if (!this.isValidPhone) {
                toComplete.push("Celular");
            }

            if (!this.isValidAddress) {
                toComplete.push("Dirección");
            }

            if (toComplete.length) {
                this.$swal.fire({
                    background: this.backgroundColor,
                    title: "Tienda invalida",
                    text: `Revisa por favor revisa los siguientes campos: ${toComplete.join(
                        ", "
                    )}`,
                    icon: "info"
                });
                return false;
            }
            return true;
        },
        getDataSend() {
            return {
                ...this.itemComponent,
                id: this.id
            };
        },
        onBlurAddress() {
            this.introValidators.address = true;
            this.itemComponent.address = this.itemComponent.address.trim();
        }
    }
};
</script>
